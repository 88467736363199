export const indexPage = [
    'Marketagent für karriere.at, Erhebungszeitraum April - Juni 2023, CAWI, n=1.502, erwerbsfähige Personen 18-60 Jahre, Vergleich rein Online-Jobbörsen',
    'Google Analytics, Erhebungszeitraum Jänner 2024, Total Users auf karriere.at Seiten mit Nutzer*innen mit zumindest einer Jobsuche-Intention',
    'Marketagent für karriere.at, Erhebungszeitraum Mai 2024, CAWI, n=406, Kunden von karriere.at',
    'Google Analytics, Erhebungszeitraum Jänner 2024, abgerundeter Wert, Total Users auf karriere.at Seiten mit Nutzer*innen mit zumindest einer Jobsuche-Intention dividiert durch 31 (Tage)',
    'Eigene Datenerhebung, Oktober 2024',
    'Marketagent für karriere.at, Erhebungszeitraum 2023',
];

export const langeBesetzungsdauer = [
    'Marketagent für karriere.at (August 2022). Befragt wurden 807 Personalverantwortliche in Österreich.',
    'Marketagent für karriere.at (Q3/2024). Befragt wurden 1.503 Erwerbsfähige in Österreich.',
    'Marketagent für karriere.at (2024). Befragt wurden 158 Personalverantwortliche in Österreich, die Active Sourcing nutzen.',
];

export const mangelAnBewerbungen = [
    'Marketagent für karriere.at (2023). Befragt wurden 1.025 Erwerbsfähige in Österreich.',
    'Google Analytics (01/ 2024), Total Users auf karriere.at Seiten mit Nutzer*innen mit zumindest einer Jobsuche-Intention.',
];

export const hoherPersonalbedarf = [
    'Marketagent für karriere.at (August 2022). Befragt wurden 807 Personalverantwortliche in Österreich.',
    'Google Analytics (01/ 2024), Total Users auf karriere.at Seiten mit Nutzer*innen mit zumindest einer Jobsuche-Intention',
    'Marketagent für karriere.at (2024). Befragt wurden 158 Personalverantwortliche in Österreich, die Active Sourcing nutzen.',
];

export const starkerMitbewerb = [
    'Marketagent für karriere.at (2024). Befragt wurden 365 Personalverantwortliche in Österreich.',
    'Marketagent für karriere.at (2024). Befragt wurden 158 Personalverantwortliche in Österreich, die Active Sourcing nutzen.',
    'Google Analytics, Erhebungszeitraum Jänner 2024, abgerundeter Wert, Total Users auf karriere.at Seiten mit Nutzer*innen mit zumindest einer Jobsuche-Intention dividiert durch 31 (Tage)',
    'Google Analytics, März 2025',
];
