<template>
    <div
        class="flex aspect-square rotate-[10deg] flex-col items-center justify-center rounded-full bg-yellow-600 p-5"
    >
        <div class="flex">
            <JamBaseText variant="3xl"> - 10 % </JamBaseText>*
        </div>
        <JamBaseText>Code: kombi10 </JamBaseText>
    </div>
</template>
